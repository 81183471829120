<template>
<div style="max-width: 800px">

  <div class="card-box-full">
    <div class="card-box-header">
      {{ service }}
      <div style="float: right; margin-right: 10px;">
        <div v-if="this.refreshTimer != null" style="cursor: pointer" @click="clickedPause()">
          <CIcon name="cil-media-pause" style="width: 20px; height: 20px" />
        </div>
        <div v-else style="cursor: pointer" @click="clickedPlay()">
          <CIcon name="cil-media-play" style="width: 20px; height: 20px" />
        </div>
      </div>
    </div>

    <!-- ------- -->
    <CTabs variant="tabs" style="margin-top: 10px; margin-bottom: -7px; padding-bottom: 10px">

      <!-- ------------------------------------------ -->
      <CTab title="Instances">
        <div class="tab-box" style="padding: 10px 10px 10px 10px;">

          <div v-if="isRefreshingService && instances.length == 0" class="spinner-border spinner-border-sm text-primary" role="status" style="">
            <span class="sr-only"></span>
          </div>
          <div v-else-if="errorMessageInstances">
            <div class="alert alert-danger" style="margin-bottom: 0px" role="alert">{{ errorMessageInstances }}</div>
          </div>
          <div v-else-if="instances.length == 0">
            <div class="alert alert-warning" style="margin-bottom: 0px" role="alert">No instances were found</div>
          </div>
          <div v-else>
            <table class="table table-responsive-sm" style="margin-top: 0px; margin-bottom: -10px;">
              <thead>
                <th>IP Address</th>
                <th>Launched</th>
                <th>Last Seen</th>
                <th style="text-align:center">In Production</th>
              </thead>
              <tbody>
                <tr v-for="instance in instances">
                  <td>{{ instance.ip }}</td>
                  <td>
                    <timeago :datetime="instance.launched"></timeago>
                  </td>
                  <td>
                    <timeago :datetime="instance.last_seen"></timeago>
                  </td>
                  <td align="center">
                    <span v-if="instance.in_production" class="badge badge-pill badge-success">YES</span>
                    <span v-else class="badge badge-pill badge-danger">NO</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </CTab>

      <!-- ------------------------------------------ -->
      <CTab title="API">
        <div class="tab-box" style="padding: 10px 10px 10px 10px;">

          <div v-if="isRefreshingService && endpoints.length == 0" class="spinner-border spinner-border-sm text-primary" role="status" style="">
            <span class="sr-only"></span>
          </div>
          <div v-else-if="errorMessageAPI">
            <div class="alert alert-danger" style="margin-bottom: 0px" role="alert">{{ errorMessageAPI }}</div>
          </div>
          <div v-else-if="endpoints.length == 0">
            <div class="alert alert-warning" style="margin-bottom: 0px" role="alert">No API endpoints were found</div>
          </div>
          <div v-else>
            <table class="table table-responsive-sm" style="margin-top: 0px; margin-bottom: -10px;">
              <thead>
                <th>Method</th>
                <th>Path</th>
                <th>Authentication</th>
                <th>Authorized</th>
              </thead>
              <tbody>
                <tr v-for="endpoint in endpoints">
                  <td>{{ endpoint.method }}</td>
                  <td>{{ endpoint.path }}</td>
                  <td>
                    <span v-if="endpoint.authentication" class="badge badge-pill badge-success">YES</span>
                    <span v-else class="badge badge-pill badge-danger">NO</span>
                  </td>
                  <td>
                    <span v-for="value in endpoint.authorized" class="badge badge-pill badge-light" style="margin-right: 5px">{{ value }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </CTab>

      <!-- ------------------------------------------ -->
      <CTab title="Requests">
        <div class="tab-box" style="padding: 10px 10px 10px 10px;">

          <div v-if="isRefreshingRequests && requests.length == 0" class="spinner-border spinner-border-sm text-primary" role="status" style="">
            <span class="sr-only"></span>
          </div>
          <div v-else-if="errorMessageRequests">
            <div class="alert alert-danger" style="margin-bottom: 0px" role="alert">{{ errorMessageRequests }}</div>
          </div>
          <div v-else-if="requests.length == 0">
            <div class="alert alert-warning" style="margin-bottom: 0px" role="alert">No requests were found</div>
          </div>
          <div v-else>
            <table class="table table-responsive-sm" style="margin-top: 0px; margin-bottom: -10px;">
              <thead>
                <th>Method</th>
                <th>Path</th>
                <th>Past Hour</th>
                <th style="text-align:center">Per Minute</th>
              </thead>
              <tbody>
                <tr v-for="request in requests">
                  <td style="padding-top: 25px">{{ request.method }}</td>
                  <td style="padding-top: 25px" width="170">{{ request.path }}</td>
                  <td>

                    <trend v-if="request.max == 0"
                      :data="request.per_minute"
                      :gradient="['#ff0000']"
                      style="strokeWidth: 2; border-bottom: 2px dashed LightGray;"
                      :width="300"
                      :height="50"
                      :padding="1"
                      smooth
                      :min="0"
                      :max="1"
                      :radius="100">
                    </trend>
                    <trend v-else
                      :data="request.per_minute"
                      :gradient="['#2ca9d6']"
                      style="strokeWidth: 2; border-bottom: 2px dashed LightGray;"
                      :width="300"
                      :height="50"
                      :padding="1"
                      smooth
                      :min="0"
                      :radius="100">
                    </trend>
                  </td>
                  <td align="center" style="padding-top: 25px">
                    <div v-if="request.per_minute.length > 0">
                      <transition name="fade" mode="out-in">
                        <div :key="request.current">
                          <span v-if="request.max == 0" class="badge badge-pill badge-danger" style="margin-right: 5px">{{ request.current }}</span>
                          <span v-else class="badge badge-pill badge-primary" style="margin-right: 5px">{{ request.current }}</span>
                        </div>
                      </transition>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </CTab>

      <!-- ------------------------------------------ -->
      <!-- <CTab title="Events">
        <div class="tab-box" style="padding: 10px 10px 10px 10px;">

          <div v-if="errorMessageEvents">
            <div class="alert alert-danger" style="margin-bottom: 0px" role="alert">{{ errorMessageEvents }}</div>
          </div>

        </div>
      </CTab> -->
    </CTabs>


  </div>

</div>
</template>

<script>
export default {
  name: 'Service',
  data() {
    return {
      endpoints: [],
      errorMessageAPI: null,
      errorMessageEvents: null,
      errorMessageInstances: null,
      errorMessageRequests: null,
      instances: [],
      isRefreshingService: false,
      isRefreshingRequests: false,
      refreshTimer: null,
      requests: [],
      service: "",
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedPause() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer);
        this.refreshTimer = null;
      }
    },
    //--------------------------------------------------------------------------
    clickedPlay() {
      if (this.refreshTimer == null) {
        this.refreshService();
        this.refreshRequests();
        var self = this;
        this.refreshTimer = setInterval(function() {
          self.refreshService();
          self.refreshRequests();
        }, 1000);
      }
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponseRequests(response) {

      // Process the instances
      if (!response || !response.requests) {
        this.errorMessageInstances = "Server did not return any requests";
      } else {

        for (var x = 0; x < response.requests.length; x++) {
          response.requests[x].max = Math.max(...response.requests[x].per_minute);
          response.requests[x].current = response.requests[x].per_minute[0];
          response.requests[x].per_minute.reverse();
        }
        this.requests = response.requests;
      }
    },
    //--------------------------------------------------------------------------
    processResponseService(response) {

      // Process the instances
      if (!response || !response.instances) {
        this.errorMessageInstances = "Server did not return any instances";
      } else {
        this.instances = response.instances;
      }

      // Process the endpoints
      if (!response || !response.endpoints) {
        this.errorMessageAPI = "Server did not return any endpoints";
      } else {
        this.endpoints = response.endpoints;
      }
    },
    //--------------------------------------------------------------------------
    refreshRequests() {
      if (this.isRefreshingRequests) {
        return;
      } else {
        this.isRefreshingRequests = true;
        this.errorMessageRequests = null;
      }

      var that = this;
      var method = "GET";
      var url = "https://telemetry.platform.robocalls.ai/service/requests?name=" + encodeURIComponent(this.service);
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshingRequests = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessageRequests = message;
            } else {
              that.errorMessageRequests = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponseRequests(result);
            } catch (err) {
              that.errorMessageRequests = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshingRequests = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshingRequests = false;
          that.errorMessageRequests = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    refreshService() {
      if (this.isRefreshingService) {
        return;
      } else {
        this.isRefreshingService = true;
        this.errorMessageInstances = null;
        this.errorMessageAPI = null;
      }

      var that = this;
      var method = "GET";
      var url = "https://telemetry.platform.robocalls.ai/service?name=" + encodeURIComponent(this.service);
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshingService = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessageInstances = message;
              this.errorMessageAPI = that.errorMessageInstances;
            } else {
              that.errorMessageInstances = "Received a code " + code + " from the service";
              this.errorMessageAPI = that.errorMessageInstances;
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponseService(result);
            } catch (err) {
              that.errorMessageInstances = "Failed to parse response from the server";
              this.errorMessageAPI = that.errorMessageInstances;
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshingService = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshingService = false;
          that.errorMessageInstances = error;
          that.errorMessageAPI = error;
        }
      })
    }
  },
  mounted() {
    if (this.$route.params.service) {
      this.service = this.$route.params.service;
      this.clickedPlay();
    }
  },
  beforeDestroy() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  }
}
</script>
